import React, { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { RightColumnWithPadding } from "../content.sc";
import pages from "./pages";

const SwiperPrivacyPolicyColumn: FC = () => {
  return (
    <RightColumnWithPadding span={16}>
      <Swiper
        modules={[Pagination, Navigation]}
        pagination={{
          type: "fraction",
        }}
        slidesPerView={1}
        navigation={true}
        loop={true}
      >
        {pages.map((page, index) => (
          <SwiperSlide key={index}>{page}</SwiperSlide>
        ))}
      </Swiper>
      <div>
        Datenschutzerklärung erstellt durch{" "}
        <a href="https://www.adsimple.de/datenschutz-generator/" target="_blank" rel="noreferrer">
          www.Adsimple.de/datenschutz-Generator/
        </a>{" "}
      </div>
    </RightColumnWithPadding>
  );
};

export default SwiperPrivacyPolicyColumn;
