import React, { FC, useRef, useState } from "react";
import debounce from 'lodash/debounce';
import intl from "./i18n/intl";
import { RawIntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import { coreTheme } from "./styles/themes/default";
import {
  LandingPageContainer,
  LandingVector,
  Grid,
  Xbg
} from "./LandingPage.sc";
import AppHeader from "./header/Header";
import Content from "./content";
import { Page } from "./types";

const pages: Page[] = ['home', 'contact', 'impressum', 'privacy_policy'];
const homeDockPosition = 64;
const homeMovedPosition = 64 * 3;

const LandingPage: FC = () => {
  const homeRef = useRef<HTMLDivElement>(null);
  const [isHomeMoved, setIsHomeMoved] = useState(false);
  const [activePage, setActivePage] = useState<Page>(pages[0]);

  const handleScroll = debounce(() => {
    if (homeRef?.current) {
      const {top} = homeRef.current.getBoundingClientRect();
      const viewport = window.innerHeight;
      const viewportMargin = viewport*0.2;
      const sensitiveArea = viewport - viewportMargin;
      const position = -1*top;

      if (position < sensitiveArea && activePage !== 'home') {
        setActivePage('home');
      } else if (position > sensitiveArea && position < 2 * sensitiveArea - 64 && activePage !== 'contact') {
        setActivePage('contact');
      } else if (position > 2 * sensitiveArea && position < 3 * sensitiveArea - 64 && activePage !== 'impressum') {
        setActivePage('impressum');
      } else if ( position > 3 * sensitiveArea && activePage !== 'privacy_policy') {
        setActivePage('privacy_policy');
      }

      if (position >= homeMovedPosition && !isHomeMoved) {
        setIsHomeMoved(true);
      }
      if (position <= homeDockPosition && isHomeMoved) {
        setIsHomeMoved(false);
      }
    }
  }, 50);

  return (
    <ThemeProvider theme={coreTheme}>
      <RawIntlProvider value={intl}>
        <LandingPageContainer onScroll={handleScroll}>
          <AppHeader isHomeMoved={isHomeMoved} activePage={activePage}/>
          <p id="home" ref={homeRef}/>
          <LandingVector />
          <Grid />
          <Xbg />
          <Content />
        </LandingPageContainer>
      </RawIntlProvider>
    </ThemeProvider>
  );
};

export default LandingPage;
