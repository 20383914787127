import { Button as AntdButton } from "antd";
import { styled } from "../styles/themes";

export const Button = styled(AntdButton)`
  background: ${props => props.theme.primary};
  border: 1px solid ${props => props.theme.primary};
  
  :hover, :focus {
    background: ${props => props.theme.secondary};
    border: 1px solid ${props => props.theme.secondary};
  }
`;
