import { createIntl, createIntlCache } from 'react-intl';
import en from './en.json';
import de from './de.json';

const cache = createIntlCache();
export let locale = 'en';
let messages = en;

if (navigator.language.includes('de')) {
  locale = 'de';
  messages = de;
}

const intl = createIntl(
  {
    locale,
    messages,
  },
  cache,
);

export const formatMessage = (id: string) => intl.formatMessage({ id });

export default intl;
