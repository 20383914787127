import React, { FC } from "react";
import {
  ContentContainer,
  LeftColumnDarkToLightPadding,
  StyledTitle,
  StyledText,
  StyledSubTitle,
} from "../content.sc";
import SwiperImpressumColumn from "./SwiperImpressumColumn";

const Impressum: FC = () => (
  <ContentContainer id="impressum">
    <LeftColumnDarkToLightPadding span={8}>
      <StyledTitle>Impressum</StyledTitle>
      <StyledText>
        Angaben gemäß § 5 TMG <br />
        Todix GmbH <br />
        Frohnstraße 43 <br />
        40789 Monheim am Rhein
      </StyledText>
      <StyledSubTitle>Vertreten durch:</StyledSubTitle>
      <StyledText>Sebastian Schmoll</StyledText>
      <StyledSubTitle>Registereintrag:</StyledSubTitle>
      <StyledText>
        Registergericht: Amtsgericht Düsseldorf <br />
        Registernummer: HRB 89491
      </StyledText>
      <StyledSubTitle>Umsatzsteuer-ID:</StyledSubTitle>
      <StyledText>
        Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
        DE 330 888 147
      </StyledText>
    </LeftColumnDarkToLightPadding>
    <SwiperImpressumColumn />
  </ContentContainer>
);

export default Impressum;
