import { styled } from '../styles/themes';
import logo from '../assets/logo.svg';

type LinearGradientProps = {
  isHomeMoved: boolean;
};

export const HeaderComponent = styled.div`
  display: flex;
  position: fixed;
  flex: 1;
  width: 100%;
  padding: 16px 20px;
  background-color: transparent;
  justify-content: space-between;
  z-index: 100;
  border-bottom: 1px solid #E6E6E6;
`;

export const LinearGradient = styled.div<LinearGradientProps>`
  position: fixed;
  background-image: linear-gradient(180deg, #009154 0%, #007242 100%);
  opacity: ${props => props.isHomeMoved ? '1' : '0'};
  transition: opacity 0.3s;
  height: 64px;
  margin: -16px -20px;
  width: 100%;
  border: none;
`;

export const HeaderLogo = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  background: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 100px;
  z-index: 100;
`;

export const LinkSection = styled.div`
  display: flex;
`;

type HeaderLinkProps = {
  isActive: boolean;
};

export const HeaderLink = styled.a<HeaderLinkProps>`
  font-family: 'Kanit', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-right: 60px;
  z-index: 100;
  color: ${props => props.isActive ? props.theme.secondary : "#FFFFFF"};
  
  :hover {
    color: ${props => props.theme.secondary};
  }
`;
