import { Card, Col, Layout, Row } from "antd";
import { styled } from "../styles/themes";
import dashboard from "../assets/dashboard.png";
import calculator from "../assets/calc.png";
import personCard from "../assets/personCard.png";
import smallCard from "../assets/smallCard.png";

const { Content } = Layout;

export const LandingPageContent = styled(Content)`
  margin-top: 64px;
  z-index: 10;
`;

export const PaddingContent = styled.div`
  padding: 16px 20px;
`;

export const PageSection = styled.section`
  min-height: 100vh;
`;

export const ContentContainer = styled(Row)`
  z-index: 1;
  height: 100vh;
  position: relative;
`;

export const LeftColumnLightToDark = styled(Col)`
  background: linear-gradient(180deg, #009154 0%, #007242 100%);
`;

export const LeftColumnDarkToLight = styled(Col)`
  background: linear-gradient(180deg, #007242 0%, #009154 100%);
`;

export const LeftColumnDarkToLightPadding = styled(LeftColumnDarkToLight)`
  justify-content: center;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
`;

export const LeftColumnLightToDarkPadding = styled(LeftColumnLightToDark)`
  justify-content: center;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
`;

export const TodixTitle = styled.div`
  font-family: 'Kanit', monospace;
  font-style: normal;
  font-weight: 300;
  font-size: 56px;
  line-height: 100%;
  /* identical to box height, or 56px */

  display: flex;
  align-items: center;

  background: linear-gradient(180deg, #FFFFFF 0%, #EFEFEF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Productive = styled.div`
  margin-top: 190px;
  margin-left: 210px;
`

export const ProductiveTitle = styled.div`
  font-family: 'Kanit',monospace;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 130%;
  /* identical to box height, or 42px */

  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  background: linear-gradient(180deg, #FFFFFF 0%, #EFEFEF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ProductiveDescription = styled.div`
  font-family: 'Kanit', serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  margin-top: 28px;
`;

export const DashboardCard = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 694px;
  max-height: 444.16px;
  left: 54%;
  top: 15%;
  background: url(${dashboard}) no-repeat transparent;
  background-size: contain;
  z-index: 3;
`;

export const Calculator = styled.div`
  position: absolute;
  width: 136.59px;
  height: 220px;
  left: 68%;
  top: 55%;
  background: url(${calculator}) no-repeat transparent;
  background-size: contain;
  z-index: 3;
`;

export const PersonCard = styled.div`
  position: absolute;
  width: 184px;
  height: 146px;
  left: 40%;
  top: calc(100vh - 200px);
  background: url(${personCard}) no-repeat transparent;
  background-size: contain;
  z-index: 3;
`;

export const SmallCard = styled.div`
  position: absolute;
  width: 183px;
  height: 127px;
  left: 80%;
  bottom: calc(100vh - 680px);
  background: url(${smallCard}) no-repeat transparent;
  background-size: contain;
  z-index: 3;
`;

export const SwiperCard = styled(Card)`
  min-width: 50%;
  font-family: 'Kanit', monospace;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.03em;
  max-height: 75%;
  overflow: auto;
  padding: 0 20px;
`;

export const StyledTitle = styled.div`
  font-family: 'Kanit', monospace;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 130%;
  display: flex;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #E6E6E6;
  opacity: 0.75;
  margin-bottom: 20px;
`;

export const StyledSubTitle = styled.div`
  font-family: 'Kanit', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  letter-spacing: 0.02em;
  color: #E6E6E6;
  opacity: 0.75;
  margin-top: 20px;
`;

export const StyledText = styled.div`
  font-family: 'Kanit', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  letter-spacing: 0.02em;
  color: #E6E6E6;
  opacity: 0.75;
`;

export const RightColumnWithPadding = styled(Col)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
  
  a {
    color: ${props => props.theme.primary};
  }
`;


