import React, { FC } from "react";
import {
  ContentContainer,
  LeftColumnLightToDarkPadding,
  StyledTitle,
  StyledText,
  StyledSubTitle,
} from "../content.sc";
import SwiperPrivacyPolicyColumn from "./SwiperPrivacyPolicyColumn";

const PrivacyPolicy: FC = () => (
  <ContentContainer id="privacy_policy">
    <LeftColumnLightToDarkPadding span={8}>
      <StyledTitle>Datenschutzbestimmungen</StyledTitle>
      <StyledText>
        Angaben gemäß § 5 TMG <br />
        Todix GmbH <br />
        Frohnstraße 43 <br />
        40789 Monheim am Rhein
      </StyledText>
      <StyledSubTitle>Vertreten durch:</StyledSubTitle>
      <StyledText>Sebastian Schmoll</StyledText>
      <StyledSubTitle>Registereintrag:</StyledSubTitle>
      <StyledText>
        Registergericht: Amtsgericht Düsseldorf <br />
        Registernummer: HRB 89491
      </StyledText>
      <StyledSubTitle>Umsatzsteuer-ID:</StyledSubTitle>
      <StyledText>
        Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
        DE 330 888 147
      </StyledText>
    </LeftColumnLightToDarkPadding>
    <SwiperPrivacyPolicyColumn />
  </ContentContainer>
);

export default PrivacyPolicy;
