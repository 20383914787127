import React, { FC } from "react";
import { Col } from "antd";
import { FormattedMessage } from "react-intl";
import { ContentContainer, LeftColumnLightToDark } from "../content.sc";
import { MailBg, TodixBeProductive } from "./contact.sc";
import ContactForm from "./ContactForm";

const Contact: FC = () => {
  return (
    <ContentContainer id="contact">
      <LeftColumnLightToDark span={8}>
        <MailBg>
          <TodixBeProductive>
            <FormattedMessage
              id="todixProductiveTitle"
              defaultMessage="Todix Be Productive"
            />
          </TodixBeProductive>
        </MailBg>
      </LeftColumnLightToDark>
      <Col span={16}>
        <ContactForm />
      </Col>
    </ContentContainer>
  );
};

export default Contact;
