import React, { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Input, Row, Col, message } from "antd";
import Firebase from "../../services/Firebase";
import { Button } from "../../common";
import {
  FormContainer,
  FormSubtitle,
  FormTitle,
  StyledCheckBox,
  StyledForm,
  StyledFormItem,
  StyledReCAPTCHA,
} from "./contact.sc";

const { TextArea } = Input;

type FormValues = {
  email: string;
  name: string;
  message?: string;
  checked?: boolean;
};

const ContactForm: FC = () => {
  const [form] = Form.useForm<FormValues>();
  const intl = useIntl();
  const [recaptchaPassed, setRecaptchaPassed] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      if (recaptchaPassed && agreed) {
        await Firebase.sendEmail(values);
        form.resetFields();
        message.success(
          intl.formatMessage({
            id: "emailSendSuccess",
            defaultMessage: "Thanks for your email",
          })
        );
        return;
      }

      if (!agreed) {
        message.error(
          intl.formatMessage({
            id: "agreedFailed",
            defaultMessage: "Please, agreed",
          })
        );
      }

      if (!recaptchaPassed) {
        message.error(
          intl.formatMessage({
            id: "recaptchaFailed",
            defaultMessage: "Please, approve you are not a bot",
          })
        );
      }
    },
    [agreed, form, intl, recaptchaPassed]
  );

  const handleRecaptchaChange = useCallback(() => {
    setRecaptchaPassed(true);
  }, []);

  const handleCheckbox = useCallback((event) => {
    setAgreed(event.target.checked);
  }, []);

  return (
    <FormContainer>
      <StyledReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
        onChange={handleRecaptchaChange}
        onErrored={() => setRecaptchaPassed(false)}
        size="compact"
      />
      <FormTitle>
        <FormattedMessage
          id="contactFormTitle"
          defaultMessage="Trete mit uns in Kontakt"
        />
      </FormTitle>
      <FormSubtitle>
        <FormattedMessage
          id="contactFormSubtitle"
          defaultMessage="Enter your e-mail address to receive all news from Todix."
        />
      </FormSubtitle>
      <StyledForm form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <StyledFormItem
              label={
                <FormattedMessage id="emailLabel" defaultMessage="E-Mail" />
              }
              name="email"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "emailRequiredMessage",
                    defaultMessage: "Please input your email address",
                  }),
                },
                {
                  type: "email",
                  message: intl.formatMessage({
                    id: "emailInvalidMessage",
                    defaultMessage: "Please enter valid email address",
                  }),
                },
              ]}
            >
              <Input type="email" placeholder="example@gmail.com" />
            </StyledFormItem>
          </Col>
          <Col span={12}>
            <StyledFormItem
              label={<FormattedMessage id="nameLabel" defaultMessage="Name" />}
              name="name"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "nameRequiredMessage",
                    defaultMessage: "Please input your name",
                  }),
                },
              ]}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: "namePlaceholder",
                  defaultMessage: "Name",
                })}
              />
            </StyledFormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <StyledFormItem
              name="message"
              label={
                <FormattedMessage id="newsLabel" defaultMessage="Nachricht" />
              }
            >
              <TextArea
                placeholder={intl.formatMessage({
                  id: "newsPlaceholder",
                  defaultMessage: "Schreibe uns hier deine Nachricht",
                })}
                rows={8}
              />
            </StyledFormItem>
          </Col>
        </Row>
        <Row>
          <StyledFormItem>
            <StyledCheckBox onChange={handleCheckbox}>
              <FormattedMessage
                id="checkBoxPart1"
                defaultMessage="Ich bin mit den"
              />{" "}
              <a href="#privacy_policy">
                <FormattedMessage
                  id="checkBoxPart2"
                  defaultMessage="Datenschutzbestimmungen"
                />
              </a>{" "}
              <FormattedMessage
                id="checkBoxPart3"
                defaultMessage="einverstanden"
              />
            </StyledCheckBox>
          </StyledFormItem>
        </Row>
        <StyledFormItem>
          <Button
            type="primary"
            shape="round"
            htmlType="submit"
            disabled={!recaptchaPassed || !agreed}
          >
            <FormattedMessage id="submit" defaultMessage="Submit" />
          </Button>
        </StyledFormItem>
      </StyledForm>
    </FormContainer>
  );
};

export default ContactForm;
