import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import "firebase/app";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

type SendEmailPaiload = {
  email: string;
  name: string;
  message: string;
};

type SendEmail = (payload: SendEmailPaiload) => Promise<void>;

const Firebase = () => {
  const app = firebase.initializeApp(config);

  const sendEmail: SendEmail = async ({ email, name, message}) => {
    await app.firestore().collection('mail').add({
      to: "info@todix.de",
      from: email,
      message: {
        subject: `Message from ${name}`,
        text: message,
        html: `<p>${message}</p>`,
      },
    })
  }

  return {
    sendEmail
  };
};

export default Firebase();
