import { styled } from "./styles/themes";
import landingVector from "./assets/landingVector.svg";
import grid from "./assets/grid.svg";
import X from "./assets/X.svg";

export const LandingPageContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  display: flex;
  background-color: transparent;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  scroll-behavior: smooth;
`;

export const LandingVector = styled.div`
  position: absolute;
  left: 0;
  top: -50px;
  width: 100%;
  height: 100%;
  max-width: 1396px;
  max-height: 965px;
  background: url(${landingVector}) no-repeat transparent;
  background-size: contain;
  z-index: 2;
`;

export const Grid = styled.div`
  position: absolute;
  left: calc(100vw - 90%);
  top: 0;
  width: 100%;
  //max-width: 1448px;
  height: 100%;
  background: url(${grid}) no-repeat transparent;
  background-size: contain;
  z-index: 1;
`;

export const Xbg = styled.div`
  position: absolute;
  left: 0;
  right: 10.38%;
  top: 0;
  bottom: 2.85%;
  width: 100%;
  height: 100%;
  background: url(${X}) no-repeat transparent;
  background-size: contain;
  z-index: 3;
`;
