import React from "react";
import ReactDOM from "react-dom";
import "animate.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./index.css";
import LandingPage from "./LandingPage";
import reportWebVitals from "./reportWebVitals";

import calc from "./assets/calc.png";
import dashboard from "./assets/dashboard.png";
import grid from "./assets/grid.svg";
import landingVector from "./assets/landingVector.svg";
import logo from "./assets/logo.svg";
import mail from "./assets/mail.svg";
import personCard from "./assets/personCard.png";
import smallCard from "./assets/smallCard.png";
import X from "./assets/X.svg";

const ImageManager = () => {
  const imagePromises = [
    calc,
    dashboard,
    grid,
    landingVector,
    logo,
    mail,
    personCard,
    smallCard,
    X,
  ].map((el) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        resolve(true);
      };
      image.src = el;
    });
  });

  return Promise.all(imagePromises);
};

ImageManager().then(() => {
  const spinner = document.getElementById("loading_spinner");

  if (spinner) {
    spinner.remove();
  }

  ReactDOM.render(
    <React.StrictMode>
      <LandingPage />
    </React.StrictMode>,
    document.getElementById("root")
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
