import React, { FC } from "react";
import {
  LandingPageContent,
  TodixTitle,
  Productive,
  ProductiveTitle,
  ProductiveDescription,
  Calculator,
  DashboardCard,
  PersonCard,
  SmallCard,
  PageSection,
  PaddingContent,
} from "./content.sc";
import { FormattedMessage } from "react-intl";
import Contact from "./contact";
import Impressum from "./impressum";
import PrivacyPolicy from "./privacyPolicy";

const Content: FC = () => {
  return (
    <LandingPageContent>
      <PageSection>
        <PaddingContent>
          <TodixTitle>
            <FormattedMessage id="todixTitle" defaultMessage="Todix" />
          </TodixTitle>
          <Productive>
            <ProductiveTitle>
              <FormattedMessage
                id="productiveTitle"
                defaultMessage="Be productive"
              />
            </ProductiveTitle>
            <ProductiveDescription>
              <FormattedMessage
                id="productiveDescription"
                defaultMessage="Eine Plattform für dein Business"
              />
            </ProductiveDescription>
          </Productive>
        </PaddingContent>
      </PageSection>
      <PageSection>
        <Contact />
      </PageSection>
      <PageSection>
        <Impressum />
      </PageSection>
      <PageSection>
        <PrivacyPolicy />
      </PageSection>
      <DashboardCard className="animate__animated animate__fadeInRight animate__faster" />
      <Calculator className="animate__animated animate__fadeInUp animate__fast" />
      <PersonCard className="animate__animated animate__fadeInUp animate__fast" />
      <SmallCard className="animate__animated animate__fadeInRight animate__fast animate__delay-1s" />
    </LandingPageContent>
  );
};

export default Content;
