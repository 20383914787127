import { Checkbox, Form } from "antd";
import { styled } from "../../styles/themes";
import mailSvg from "../../assets/mail.svg";
import ReCAPTCHA from "react-google-recaptcha";

const { Item } = Form;

export const MailBg = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: url(${mailSvg}) no-repeat transparent center;
`;

export const TodixBeProductive = styled.div`
  font-family: 'Kanit', monospace;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-align: center;
  color: #E6E6E6;
  opacity: 0.75;
  padding-top: 260px;
`;

export const FormContainer = styled.div`
  margin: 150px 0 0 150px;
  padding: 0 10%;
`;

export const FormTitle = styled.div`
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 130%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #031A10;
`;

export const FormSubtitle = styled.div`
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-top: 20px;
`;

export const StyledForm = styled(Form)`
  margin-top: 20px;
`;

export const StyledFormItem = styled(Item)`
  label {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #555957;
  }
  
  .ant-checkbox-wrapper {
    text-transform: none;
  }
`;

export const StyledCheckBox = styled(Checkbox)`
  label {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    color: #031A10;
  }
  
  a {
    text-decoration: underline;
    color: ${props => props.theme.primary};
  }
  
  a:hover {
    color: ${props => props.theme.secondary};
  }
  
  .ant-checkbox-inner {
    border: 1.5px solid ${props => props.theme.primary};
    box-sizing: border-box;
    border-radius: 1px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.primary};
    border-color: ${props => props.theme.primary};
  }
  
  :hover {
    .ant-checkbox-inner {
      color: ${props => props.theme.secondary};
    }
  }
`;

export const StyledReCAPTCHA = styled(ReCAPTCHA)`
  z-index: 100;
  position: fixed;
  right: 0;
  bottom: 0;
`;
