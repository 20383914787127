import React, { FC } from "react";
import { Button } from "../common";
import {
  HeaderLogo,
  HeaderComponent,
  LinkSection,
  HeaderLink, LinearGradient
} from "./Header.sc";
import { FormattedMessage } from "react-intl";
import { Page } from "../types";

const TodixLink = "https://todix.netlify.app/";

type HeaderProps = {
  isHomeMoved: boolean;
  activePage: Page;
};

const Header: FC<HeaderProps> = ({ isHomeMoved, activePage }) => {
  return (
      <HeaderComponent >
        <LinearGradient isHomeMoved={isHomeMoved}/>
        <LinkSection>
          <HeaderLogo />
          <LinkSection>
            <HeaderLink href="#home" isActive={activePage === 'home'}>
              <FormattedMessage id="headerHomeLink" defaultMessage="Home" />
            </HeaderLink>
            <HeaderLink href="#contact" isActive={activePage === 'contact'}>
              <FormattedMessage
                id="headerKontaktLink"
                defaultMessage="Kontakt"
              />
            </HeaderLink>
            <HeaderLink href="#impressum" isActive={activePage === 'impressum'}>
              <FormattedMessage
                id="headerImpressumLink"
                defaultMessage="Impressum"
              />
            </HeaderLink>
            <HeaderLink href="#privacy_policy" isActive={activePage === 'privacy_policy'}>
              <FormattedMessage
                id="headerPrivacyPolicyLink"
                defaultMessage="Datenschutz"
              />
            </HeaderLink>
          </LinkSection>
        </LinkSection>
        <Button type="primary" shape="round" role="link">
          <a href={TodixLink}>
            <FormattedMessage id="logInButton" defaultMessage="Log In" />
          </a>
        </Button>
      </HeaderComponent>
  );
};

export default Header;
