import React, { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { RightColumnWithPadding } from "../content.sc";
import pages from "./pages";

const SwiperImpressumColumn: FC = () => {
  return (
    <RightColumnWithPadding span={16}>
      <Swiper
        modules={[Pagination, Navigation]}
        pagination={{
          type: "fraction",
        }}
        slidesPerView={1}
        navigation={true}
        loop={true}
      >
        {pages.map((page, index) => (
          <SwiperSlide key={index}>{page}</SwiperSlide>
        ))}
      </Swiper>
      <div>
        Website Impressum erstellt durch{" "}
        <a href="https://www.impressum-generator.de/" target="_blank" rel="noreferrer">
          impressum-generator.de
        </a>{" "}
        von der{" "}
        <a href="https://www.kanzlei-hasselbach.de/" target="_blank" rel="noreferrer">
          Kanzlei Hasselbach
        </a>
      </div>
    </RightColumnWithPadding>
  );
};

export default SwiperImpressumColumn;
