import React from 'react';
import { SwiperCard } from '../content.sc';

const pages = [
  <SwiperCard title="Datenschutz" key="0" id="privacyprotection_page_0">
    Wir haben diese Datenschutzerklärung (Fassung 10.12.2020-311239735) verfasst, um
    Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 zu erklären,
    welche Informationen wir sammeln, wie wir Daten verwenden und welche
    Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
    Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir
    haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie
    möglich zu beschreiben.
  </SwiperCard>,
  <SwiperCard title="Automtische Datenspeicherung" key="1" id="privacyprotection_page_1">
    Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch
    erstellt und gespeichert, so auch auf dieser Webseite.
    Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver
    (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie
    ● die Adresse (URL) der aufgerufenen Webseite
    ● Browser und Browserversion
    ● das verwendete Betriebssystem
    ● die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
    ● den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird
    ● Datum und Uhrzeit
    in Dateien (Webserver-Logfiles).
    In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch
    gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese
    Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
  </SwiperCard>,
  <SwiperCard title="Cookies" key="2" id="privacyprotection_page_2">
    Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.
    Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die
    folgende Datenschutzerklärung besser verstehen.
    Was genau sind Cookies?
    Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte
    Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge.
    Die meisten Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien
    nennt man Cookies.
    Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle
    Webseiten verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch
    noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine
    Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese
    Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers,
    untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition
    eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.
    Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder
    persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr
    Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß
    unsere Website, wer Sie sind und bietet Ihnen Ihre gewohnte Standardeinstellung. In einigen
    Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind
    alle Cookies in einer einzigen Datei gespeichert.
    Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies
    werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von
    Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten,
    da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein
    paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und
    enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf
    Informationen Ihres PCs zugreifen.
    So können zum Beispiel Cookie-Daten aussehen:
    ● Name: _ga
    ● Ablaufzeit: 2 Jahre
    ● Verwendung: Unterscheidung der Webseitenbesucher
    ● Beispielhafter Wert: GA1.2.1326744211.152311239735
    Ein Browser sollte folgende Mindestgrößen unterstützen:
    ● Ein Cookie soll mindestens 4096 Bytes enthalten können
    ● Pro Domain sollen mindestens 50 Cookies gespeichert werden können
    ● Insgesamt sollen mindestens 3000 Cookies gespeichert werden können
    Welche Arten von Cookies gibt es?
    Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten
    Diensten ab und wird in der folgenden Abschnitten der Datenschutzerklärung geklärt. An
    dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.
    Man kann 4 Arten von Cookies unterscheiden:
    Unbedingt notwendige Cookies
    Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum
    Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann
    auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der
    Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
    Funktionelle Cookies
    Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige
    Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und
    das Verhalten der Website bei verschiedenen Browsern gemessen.
    Zielorientierte Cookies
    Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden
    eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.
    Werbe-Cookies
    Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User
    individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig
    sein.
    Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser
    Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem
    Cookie gespeichert.
    Wie kann ich Cookies löschen?
    Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von
    welchem Service oder welcher Website die Cookies stammen, haben Sie immer die
    Möglichkeit Cookies zu löschen, nur teilweise zuzulassen oder zu deaktivieren. Zum Beispiel
    können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.
    Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn
    Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren
    Browser-Einstellungen finden:
    Chrome: Cookies in Chrome löschen, aktivieren und verwalten
    Safari: Verwalten von Cookies und Websitedaten mit Safari
    Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer
    abgelegt haben
    Internet Explorer: Löschen und Verwalten von Cookies
    Microsoft Edge: Löschen und Verwalten von Cookies
    Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten,
    dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem
    einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise
    ist je nach Browser verschieden. Am besten ist es Sie suchen die Anleitung in Google mit
    dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle
    eines Chrome Browsers oder tauschen das Wort “Chrome” gegen den Namen Ihres
    Browsers, z.B. Edge, Firefox, Safari aus.
    Wie sieht es mit meinem Datenschutz aus?
    Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das
    Speichern von Cookies eine Einwilligung von Ihnen verlangt. Innerhalb der EU-Länder gibt
    es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Deutschland
    wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die
    Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).
    Wenn Sie mehr über Cookies wissen möchten und technischen Dokumentationen nicht
    scheuen, empfehlen wir https://tools.ietf.org/html/rfc6265, dem Request for Comments der
    Internet Engineering Task Force (IETF) namens “HTTP State Management Mechanism”.
  </SwiperCard>,
  <SwiperCard title="Speicherung persönlicher Daten" key="3" id="privacyprotection_page_3">
    Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum
    Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen
    der Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam
    mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet,
    sicher verwahrt und nicht an Dritte weitergegeben.
    Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern,
    die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite
    angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne
    Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim
    Vorliegen von rechtswidrigem Verhalten eingesehen werden.
    Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite –
    können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir
    empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.
    Die Rechtsgrundlage besteht nach Artikel 6 Absatz 1 a DSGVO (Rechtmäßigkeit der
    Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen
    eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen – eine
    formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.
  </SwiperCard>,
  <SwiperCard title="Rechte Laut Datenschutzgrundverordnung" key="4" id="privacyprotection_page_4">
    Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:
    ● Recht auf Berichtigung (Artikel 16 DSGVO)
    ● Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
    ● Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
    ● Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der
    Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der
    Verarbeitung (Artikel 19 DSGVO)
    ● Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
    ● Widerspruchsrecht (Artikel 21 DSGVO)
    ● Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung —
    einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel
    22 DSGVO)
    Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt
    oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind,
    können Sie sich an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
    (BfDI) wenden.
  </SwiperCard>,
  <SwiperCard title="Auswertung des Besucherverhaltens" key="5" id="privacyprotection_page_5">
    In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten
    Ihres Besuchs dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in
    der Regel anonym und wir können von Ihrem Verhalten auf dieser Website nicht auf Ihre
    Person schließen.
    Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren
    Sie in der folgenden Datenschutzerklärung.
  </SwiperCard>,
  <SwiperCard title="Google Fonts Datenschutzerklärung" key="6" id="privacyprotection_page_6">
    Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der
    Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited
    (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.
    Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein
    Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert.
    Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains
    fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen
    nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn
    Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre
    Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt
    werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der
    verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung
    genau aussieht, werden wir uns noch im Detail ansehen.
    Was sind Google Fonts?
    Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die
    Google seinen Nutzern kostenlos zu Verfügung stellt.
    Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während
    andere unter der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.
    Warum verwenden wir Google Fonts auf unserer Webseite?
    Mit Google Fonts können wir auf unserer eigenen Webseite Schriften nutzen, doch müssen
    sie nicht auf unseren eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um
    die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für
    das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung mit
    mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige
    Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts.
    Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern,
    Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler
    können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen
    Content Delivery Networks (CDN) gibt es mit Google Fonts keine plattformübergreifenden
    Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox,
    Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen
    Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir
    verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und
    einheitlich wie möglich darstellen können.
    Welche Daten werden von Google gespeichert?
    Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server
    nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt.
    So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besuchen. Die
    Google Fonts API wurde entwickelt, um Verwendung, Speicherung und Erfassung von
    Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften
    nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter
    anderem als Datenübermittler im Softwarebereich.
    Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit
    geschützt. Durch die gesammelten Nutzungszahlen kann Google feststellen, wie gut die
    einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht Google auf internen
    Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten
    des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften
    verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts
    veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice BigQuery, um
    große Datenmengen untersuchen und bewegen zu können.
    Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen
    wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des
    Browsers und Name des Browsers automatisch an die Google-Server übertragen werden.
    Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google
    nicht eindeutig kommuniziert.
    Wie lange und wo werden die Daten gespeichert?
    Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die
    hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines
    Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über
    die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.
    Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel,
    die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf
    die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert
    und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal
    aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von
    Sprache zu erhöhen und das Design zu verbessern.
    Wie kann ich meine Daten löschen bzw. die Datenspeicherung
    verhindern?
    Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht
    werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese
    Daten vorzeitig löschen zu können, müssen Sie den Google-Support auf
    https://support.google.com/?hl=de&tid=311239735 kontaktieren. Datenspeicherung
    verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.
    Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle
    Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das
    Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden
    Sie auf https://developers.google.com/fonts/faq?tid=311239735. Dort geht zwar Google auf
    datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über
    Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise
    Informationen über gespeicherten Daten zu bekommen.
    Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet
    werden, können Sie auch auf https://www.google.com/intl/de/policies/privacy/ nachlesen.
  </SwiperCard>
];

export default pages;
